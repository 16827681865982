import Close from "./Close"

class Dropdown {

  static hiddenClass = 'nav-group__dropdown--hidden'

  constructor() {
    this.buttons = document.querySelectorAll('.nav-group__button')
    if (this.buttons.length > 0) {
      this.listen()
    }
  }

  listen() {
    document.addEventListener('click', ev => {
      if (ev.target.classList.contains('nav-group__button')) {
        let dropdown = ev.target.nextElementSibling
        console.log("CLick!")
        if (dropdown.classList.contains('nav-group__dropdown--hidden')) {
          Dropdown.close()
          dropdown.classList.remove('nav-group__dropdown--hidden')
        } else {
          dropdown.classList.add('nav-group__dropdown--hidden')
        }
      } else {
        Dropdown.close()
      }

    })
  }

  open(dropdown) {
    dropdown.classList.remove(Dropdown.hiddenClass)
    Close.modals('dropdown')
  }

  static close() {
    const openDropdown = document.querySelector(`.nav-group__dropdown:not(.${Dropdown.hiddenClass})`)
    if (openDropdown) {
      openDropdown.classList.add(Dropdown.hiddenClass)
    }
  }

}

export default Dropdown